<template>
  <v-card>
    <v-card-title> Cadastro </v-card-title>

    <div class="pa-5">
      <v-row
        ><v-col>
          <v-radio-group v-model="form.type" row>
            <v-radio label="Pessoa fisíca" value="physical-person"></v-radio>
            <v-radio
              label="Pessoa jurídica"
              value="legal-person"
            ></v-radio> </v-radio-group></v-col
      ></v-row>
      <div v-if="form.type === 'physical-person'">
        <v-row>
          <v-col>
            <v-text-field
              label="Nome"
              v-model="form.firstName"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Sobrenome"
              v-model="form.lastName"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="CPF"
              v-model="form.taxDocument"
              v-mask="'###.###.###-##'"
              :rules="[rules.taxDocument]"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <div v-if="form.type === 'legal-person'">
        <v-row>
          <v-col>
            <v-text-field
              label="Razão social"
              v-model="form.companyName"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Nome fantasia"
              v-model="form.fantasyName"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="CNPJ"
              v-model="form.taxDocument"
              :rules="[rules.taxDocument]"
              v-mask="'##.###.###/####-##'"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col>
          <v-text-field
            label="E-Mail"
            v-model="form.email"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Senha"
            type="password"
            required
            v-model="form.password"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Confirmar senha"
            type="password"
            required
            v-model="form.confirmPassword"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close-dialog')">Cancelar</v-btn>

      <v-btn class="primary darken-1" @click="save()" :loading="loading">
        <v-icon left>mdi-content-save</v-icon> Salvar
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="successDialog" max-width="400">
      <v-card>
        <v-card-title> Cadastro realizado com sucesso </v-card-title>
        <v-card-text>
          Olá, ficamos felizes em ter você por aqui. Agora você já pode acessar
          o seu painel de anunciante
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text to="/">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mask } from "vue-the-mask";
import errorHandler from "@/helpers/error_handler";
import { mapActions } from "vuex";
import taxDocumentHelper from "@/helpers/tax_document";

export default {
  directives: { mask },
  data: () => ({
    loading: false,
    successDialog: false,
    valid: true,
    form: {
      type: "physical-person",
    },
    rules: {
      taxDocument: (value) => {
        if (!value) return true;

        if (value.length <= 14) {
          return taxDocumentHelper.checkCPF(value) || "CPF Inválido";
        } else {
          return taxDocumentHelper.checkCNPJ(value) || "CNPJ Inválido";
        }
      },
    },
  }),
  computed: {},
  methods: {
    ...mapActions("user", ["vSignup"]),
    validateForm() {
      const errors = [];

      if (this.form.type === "physical-person") {
        if (!this.form.firstName || this.form.firstName.length < 3)
          errors.push("O campo NOME é obrigatório");

        if (!this.form.lastName || this.form.lastName.length < 3)
          errors.push("O campo SOBRENOME é obrigatório");

        if (!this.form.taxDocument) errors.push("O campo CPF é obrigatório");
      } else if (this.form.type === "legal-person") {
        if (!this.form.companyName || this.form.companyName.length < 3)
          errors.push("O campo RAZÃO SOCIAL é obrigatório");

        if (!this.form.fantasyName || this.form.fantasyName.length < 3)
          errors.push("O campo NOME FANTASIA é obrigatório");

        if (!this.form.taxDocument) errors.push("O campo CNPJ é obrigatório");
      }

      if (!this.form.password || this.form.password.length < 6)
        errors.push(
          "O campo SENHA é obrigatório e precisa ter no mínimo 6 caracteres "
        );

      if (this.form.password != this.form.confirmPassword)
        errors.push("Os campos SENHA e CONFIRMAR SENHA não conferem");

      return errors;
    },
    save() {
      const errors = this.validateForm();

      if (errors.length) return this.$root.$errorDialog(errors);

      this.signup();
    },
    async signup() {
      try {
        this.loading = true;

        await this.vSignup(this.form);

        this.successDialog = true;
      } catch (error) {
       console.error(error);

        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>